import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    stepper: {
        backgroundColor: 'inherit'
    }
}));

export { useStyles }