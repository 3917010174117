import React from 'react'
import { Container, Typography, Box } from '@material-ui/core'
import Paper from '../../../Paper'
import SuccessSVG from '../../../../images/Success.svg'
import Button from '../../../Button'
import { Link } from 'gatsby'

const Approved = () => {
    return (
        <Container style={{ maxWidth: 600 }}>
            <Paper>
                <Box textAlign='center'>
                    
                <img src={SuccessSVG} alt="success"  style={{ height: `8rem` }}/>
                <br/>
                <br/>
                <Typography variant="h4" gutterBottom align="center">
                    Loan Request Successful!
                </Typography>
                <Typography variant="body1" color="textSecondary" gutterBottom align="center">
                    You'll be notified on your approval status
                </Typography>
                <br/>
                <br/>
                <Button color="primary" variant="contained" component={Link} to="/dashboard">
                    Go to Dashboard
                </Button>
                
                </Box>
            </Paper>
        </Container>
    )
}

export default Approved
