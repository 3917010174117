import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import {StepConnector} from '@material-ui/core'

const QontoConnector = withStyles(theme => ({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
        borderColor: theme.palette.primary.main,
        },
    },
    completed: {
        '& $line': {
        borderColor: theme.palette.primary.main,
        },
    },
    line: {
        borderColor: 'rgba(17, 122, 243, 0.3)',
        borderTopWidth: 7,
        borderRadius: 1,
    },
}))(StepConnector);

const useQontoStepIconStyles = makeStyles(theme => ({
    root: {
      color: theme.palette.primary.light,
      display: 'flex',
      height: 26,
      alignItems: 'center',
    },
    active: {
      color: theme.palette.primary.main,
    },
    circle: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: theme.palette.primary.main,
      zIndex: 1,
      fontSize: 22,
    },
  }));
  
  const QontoStepIcon = (props) => {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
  }
  
  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
  };

  export { QontoConnector, QontoStepIcon }