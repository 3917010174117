import React, { useContext } from 'react'

import { Box, Container, Step, Stepper, StepLabel } from '@material-ui/core'
import { QontoConnector, QontoStepIcon } from './QontoConnector'
import { useStyles } from './style'
import VerifyStudent from './VerifyStudent'
import LoanRequestForm from './LoanRequestForm'
import { ActiveStepContext } from '../../../context/CredPalContext'
import Approved from './Approved'


const getSteps = () => {
    return ['Verify Student', 'Fill Application']
}

const getStepContent = (stepIndex, location) => {
    switch (stepIndex) {
        case 0:
            return <VerifyStudent location={location} />
        case 1:
            return <LoanRequestForm/>
        default:
            return 'nothing to see here';
    }
}

const CredPalForm = ({location}) => {
    const classes = useStyles();

    const steps = getSteps()

    const [ activeStep ] = useContext(ActiveStepContext)

    
    // const handleBack = () => {
    //     setActiveStep(prevActiveStep => prevActiveStep - 1)
    // }
    // const handleNext = () => {
    //     setActiveStep(prevActiveStep => prevActiveStep + 1)        
    // }
    // const handleReset = () => {
    //     setActiveStep(0)
    // }


    return (
        <Box paddingTop="5rem" paddingBottom="5rem">
            <Container style={{ maxWidth: 800 }}>
                <Stepper 
                activeStep={activeStep} 
                alternativeLabel 
                connector={<QontoConnector />}
                className={classes.stepper}
                >
                    {steps.map(label => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                    ))}
                </Stepper>

                <div>
                    {activeStep === steps.length ? (
                    <div>
                        {/* { loanRequestResponse.requestSuccessful === true ? 
                        (
                            '<LoanApproved />'
                        ) : (
                            '<LoanFailed resetFunc={handleReset} />'
                        )
                        } */}
                        <Approved/>
                        {/* <Button onClick={handleReset}>Reset</Button> */}
                    </div>
                    ) : (
                    <div>
                        {/* display step component  */}
                        <div>
                            {/* pass next function handler. So the component can also move to the next step */}
                            {getStepContent(activeStep, location)}
                        </div>


                        {/* <div>
                        <Button
                            disabled={activeStep === 0}
                            onClick={handleBack}
                            className={classes.backButton}
                        >
                            Back
                        </Button>
                        <Button 
                        variant="contained" 
                        color={activeStep === steps.length - 1 ? 'secondary' : 'primary'}
                        onClick={handleNext}>
                            {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                        </Button>
                        </div> */}


                    </div>
                    )}
                </div>
            </Container>
        </Box>
    )
}

CredPalForm.propTypes = {

}

export default CredPalForm
