import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    formControl: {
        minWidth : `100%`,
        margin : theme.spacing(1),
    },
    radioGroup: {
        justifyContent : 'center',
        alignItems : 'center'
    },
    radio: {
        backgroundColor : theme.palette.primary.lighter,
        padding : '.4rem 1.2rem .4rem .4rem',
        borderRadius : '8px',
        marginLeft : '0 !important',
        marginRight : '0 !important',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    radioIcon: {
        color: theme.palette.text.main,
        '&:checked': {
            color: theme.palette.text.main,
        },
    },
    radioActive: {
        backgroundColor : theme.palette.primary.main,
        padding : '.4rem 1.2rem .4rem .4rem',
        borderRadius : '8px',
        marginLeft : '0 !important',
        marginRight : '0 !important',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    radioActiveIcon: {
        color: `${theme.palette.background.paper} !important`,
        '&:checked': {
            color: `${theme.palette.background.paper} !important`,
        },
    },
    typographyActive: {
        color: theme.palette.background.paper,
    },
    or: {
        paddingRight: '1.5rem',
        paddingLeft: '1.5rem',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingTop: '.5rem',
            paddingBottom: '.5rem',
        }
    },
}));

export { useStyles }