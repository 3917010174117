import React, { useState, useEffect, useContext } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { FormControl, FormHelperText, TextField, Box, Container, Radio, RadioGroup, FormControlLabel, InputLabel, Select, MenuItem, Typography } from '@material-ui/core'
import { useStyles } from './style'
import Paper from '../../../Paper'
import Button from '../../../Button'
import { VerifyDataContext, ActiveStepContext, InstitutionContext } from '../../../../context/CredPalContext'
import AuthContext from '../../../../context/auth/authContext'
import api from '../../../../services/Api'
import queryString from 'query-string'


const VerifyStudent = props => {
    const classes = useStyles();

    const [isSuccessful, setIsSuccessful] = useState()
    const { user } = useContext(AuthContext)
    const { dispatchVerifyData } = useContext(VerifyDataContext)
    const [ , setActiveStep ] = useContext(ActiveStepContext)
    const [ otherInstitution, setOtherInstitution ] = useContext(InstitutionContext)
    const [institutions, setInstitutions] = useState()
    // Get query string from URL
    const urlQuery = queryString.parse(props.location.search) 
    // schoolCode=ABUDLC&regno=FINMBA3&email=gbemimk4@gmail.com&amount=40000

    
    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)        
    }

   
    const initialValues = {
        Email: urlQuery?.email ? urlQuery.email : user !== null ? user.email : '',
        // Email: urlQuery?.email ? urlQuery.email : '',
        institutionCode: urlQuery?.schoolCode  ? urlQuery?.schoolCode : '',
        institutionName: '',
        RegNo: urlQuery?.regno  ? urlQuery?.regno : '',
        loanType: '',
    }
    

    const validationSchema = Yup.object({
        Email: Yup.string().email('Invalid email format!').required('Email is empty'),
        institutionCode: Yup.string().required('institution is empty'),
        institutionName: Yup.string().when("institutionCode", {
            is: "other",
            then: Yup.string().required("Institution Name is empty")}),
        RegNo: Yup.string().required('Registration number is empty'),
        loanType: Yup.string().required('Pick a loan type'),
    })

    const onSubmit = async (values) => {
        
           if(otherInstitution){
            dispatchVerifyData({ 
                type : 'UPDATE_VERIFY_DATA', 
                verifyData: {
                    loanType: values.loanType,
                    amount: urlQuery?.amount,
                    regNo: urlQuery.regno ? urlQuery.regno : values.RegNo,
                    organizationCode: '',
                    organizationName: values.institutionName,
                    email: values.Email
                }
            })
               handleNext()
           }else{
        //get institution name
        const institutionObj = institutions.find(el => el.code === values.institutionCode)
        
        if (institutionObj) {
            // acc.push(institutionsObj);
            values = {
                ...values,
                institutionName: institutionObj.name
            }
        }


        // API CALL
        try {
            const res = await api.post(`/api/BeneficiaryDetail/verify`, values)
            // console.log(res, 'verify endpoint');
            if( res.data.requestSuccessful === true ) {
                dispatchVerifyData({ 
                    type : 'UPDATE_VERIFY_DATA', 
                    verifyData: {
                        res: res.data.responseData,
                        loanType: values.loanType,
                        amount: urlQuery?.amount,
                        regNo: urlQuery.regno ? urlQuery.regno : values.RegNo,
                        organizationCode: values.institutionCode,
                        organizationName: values.institutionName
                    }
                })
                handleNext()
            } else {
                setIsSuccessful({
                    status: false,
                    message: res.data.message
                })
            }            

        } catch (error) {
            //error state Unsuccessful 
            setIsSuccessful({
                status: false,
            })
        }
    }
    }

    const getInstitutions = async () => {
        try {
            const res = await api.get(`/api/institution`)
            setInstitutions([...res.data.responseData, {
                id:"other",
                name: "OTHER INSTITUTIONS",
                code: "other"
            }])
        } catch (error) {

        }
    }

    useEffect(() => {
        getInstitutions()
       
    }, [])

    return (
        <Container style={{ maxWidth: 600 }}>
            <Paper>
                <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
                {({values, errors, touched, getFieldProps, handleBlur, setFieldValue, handleChange, isSubmitting}) => (
                    <Form noValidate autoComplete="off">
                        
                        <FormControl className={classes.formControl}>
                        {/* { console.log(values)} */}
                            <RadioGroup 
                                className={classes.radioGroup} 
                                row aria-label="loanType" name="loanType" 
                                value={values.loanType} 
                                { ...getFieldProps('loanType')}
                                // error={errors.loanType  && touched.loanType ? true : false}
                                // helperText={ errors.loanType && touched.loanType ?
                                //     errors.loanType : null
                                // }
                            >

                            <FormControlLabel 
                            value="SelfSponsored" 
                            className={values.loanType === "SelfSponsored" ? classes.radioActive : classes.radio} 
                            control={<Radio color="default" className={values.loanType === "SelfSponsored" ? classes.radioActiveIcon : classes.radioIcon} />} 
                            label={<Typography variant="caption" className={values.loanType === "SelfSponsored" ? classes.typographyActive : null }>I am a Student</Typography>}
                            />

                            <Typography className={classes.or} variant="body2" align='center'>OR</Typography>

                            <FormControlLabel 
                            value="GuardianSponsored" 
                            className={values.loanType === "GuardianSponsored" ? classes.radioActive : classes.radio}
                            control={<Radio color="default" className={values.loanType === "GuardianSponsored" ? classes.radioActiveIcon : classes.radioIcon}/>} 
                            label={<Typography variant="caption" className={values.loanType === "GuardianSponsored" ? classes.typographyActive : null }>I am a Sponsor</Typography>}
                            />

                            </RadioGroup>
                            <FormHelperText error={errors.loanType && touched.loanType ? true : false}>
                                { errors.loanType && touched.loanType ?
                                    errors.loanType : null
                                }
                            </FormHelperText>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <TextField 
                            name="Email" 
                            id="Email" 
                            label="Student Email Address"
                            { ...getFieldProps('Email')}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={errors.Email && touched.Email ? true : false}
                            helperText={ errors.Email && touched.Email ?
                                errors.Email : null
                            }
                            />
                        </FormControl>
                        
                        <FormControl variant="outlined" className={classes.formControl}>
                        <InputLabel id="institutionCodeLabel">Institution</InputLabel>
                            <Select
                                labelId="institutionCodeLabel"
                                id="institutionCode"
                                name="institutionCode"
                                onBlur={handleBlur}
                                onChange={
                                    e => {
                                        const val = e.target.value
                                        val === "other" ? setOtherInstitution(true) : setOtherInstitution(false)
                                        setFieldValue('institutionCode', val)
                                    } 
                                        
                                }
                                value={values.institutionCode}
                                label="institutionCode"
                            >
                                {!institutions ? (
                                    <MenuItem value={null} defaultValue>Loading Institutions...</MenuItem>
                                ) : (
                                    institutions.map((institution, key) => (
                                        <MenuItem key={key} value={institution.code}>{institution.name}</MenuItem>
                                    ))
                                )}
                            </Select>
                        </FormControl>

                        <Box display={otherInstitution ? 'block' : 'none'}>
                            <FormControl className={classes.formControl}>
                                <TextField 
                                name="institutionName" 
                                // id="RegNo" 
                                label="Name Of Institution"
                                { ...getFieldProps('institutionName')}
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={errors.institutionName  && touched.institutionName ? true : false}
                                helperText={ errors.institutionName && touched.institutionName ?
                                    errors.institutionName : null
                                }
                                />
                            </FormControl>
                        </Box>
                                    
                        <FormControl className={classes.formControl}>
                            <TextField 
                            name="RegNo" 
                            id="RegNo" 
                            label="Student Registration Number"
                            { ...getFieldProps('RegNo')}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={errors.RegNo  && touched.RegNo ? true : false}
                            helperText={ errors.RegNo && touched.RegNo ?
                                errors.RegNo : null
                            }
                            />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                            <Button 
                            variant="contained" 
                            color="primary" 
                            type="submit"
                            disabled={isSubmitting}
                            loading={isSubmitting}
                            >
                                Verify Student
                            </Button>
                        </FormControl>
                        
                        <FormControl className={classes.formControl}>
                            <FormHelperText 
                            style={{ textAlign: 'center' }} 
                            error={true}>
                                {isSuccessful?.status === false ? isSuccessful.message ? isSuccessful.message : 'an error occured' : null}
                            </FormHelperText>
                        </FormControl>
                        
                    </Form>
                )}
                </Formik>
            </Paper>
        </Container>
    )
}

VerifyStudent.propTypes = {

}

export default VerifyStudent
