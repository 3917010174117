import React, { useEffect, useState, useContext } from 'react'
import { Formik, Form, getIn } from 'formik'
import * as Yup from 'yup'
import { FormControl, FormHelperText, FormLabel, Select, MenuItem, InputLabel, TextField, Container, Radio, RadioGroup, FormControlLabel, Checkbox, Typography, Box, Button as MuiButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { DropzoneArea } from 'material-ui-dropzone';
import { useStyles } from './style'
import Paper from '../../../Paper'
import Button from '../../../Button'
import { VerifyDataContext, ActiveStepContext, InstitutionContext } from '../../../../context/CredPalContext'
import api from '../../../../services/Api'

const LoanRequestForm = props => {
    
    
    const classes = useStyles()

    const [ banks, setBanks ] = useState([{
        bankCode: null,
        bankName: 'No Bank Available',
    }])

    const loadBanks = async () => {
        
        try {
            const res = await api.get(`/api/Bank`)

            if( res.data.requestSuccessful === true ) {
                setBanks(res.data.responseData)
                
            } else {
                setBanks([{
                    bankCode: null,
                    bankName: 'No Bank Available',
                }])
            } 

        } catch (error) {
            setBanks([{
                bankCode: null,
                bankName: 'No Bank Available',
            }])
        }

    }

    useEffect(() => {
        loadBanks()
        
    }, [])

    const [isSuccessful, setIsSuccessful] = useState()

    const { verifyData } = useContext(VerifyDataContext)
    const [ otherInstitution ] = useContext(InstitutionContext)

    const { loanType, verifiedData, amount, regNo, organizationCode, organizationName } = verifyData
    
 
    const initialValues = {
        organizationCode: verifiedData ? organizationCode : "other",
        providerCode: 500,
        regNo: regNo ? regNo : '',
        firstName: verifiedData && verifiedData.FirstName,
        lastName: verifiedData && verifiedData.LastName,
        address: verifiedData && verifiedData.Address1,
        dateOfBirth: verifiedData && verifiedData.DateOfBirth,
        // placeOfBirth: "",
        gender: verifiedData && verifiedData.Sex,
        email: verifiedData ? verifiedData.Email : verifyData.email,
        phoneNumber: verifiedData ? verifiedData.TelePhone : '',
        identificationType: '',
        idNumber: '',
        other: otherInstitution,


        sponsorInfo: {
            firstName: loanType === 'SelfSponsored' ? verifiedData ? verifiedData.FirstName : '' : '',
            lastName: loanType === 'SelfSponsored' ? verifiedData ? verifiedData.LastName : '' : '',
            phoneNumber: loanType === 'SelfSponsored' ? verifiedData ? verifiedData.TelePhone : '' : '',
            email: loanType === 'SelfSponsored' ? verifiedData ? verifiedData.Email : verifyData.email : '',
            bvn: '',
            relationship: loanType === 'SelfSponsored' ? 'Self' : '',
            // relationship: loanType === 'SelfSponsored' ? 'Self' : '',
        },

        guarantors :[{
            firstName: "",
            lastName: "",
            phoneNumber: "",
            relationship: "",
            },
            {
            firstName: undefined,
            lastName: undefined,
            phoneNumber: undefined,
            relationship: undefined,
            }
        ],

        bankAccount: {
            accountNumber: '',
            accountName: '',
            bankCode: ''
        },

        employment: {
            employmentStatus: '',
            employerName: '',
            employerAddress: '',
            workplaceEmail: '',
            salaryAmount: 0,
            salaryDueDate: '',
        },

        institutionInfo: {
            name: organizationName ? organizationName : "",
            code: verifiedData ? organizationCode : "other",
            bankAccountNo: '',
            bankAccountName: '',
            bankName: ''
        },

        loanRequest: {
            loanAmount: amount ? amount : '',
            tenure: '',
            isSelfSponsored: loanType === 'SelfSponsored' ? true : false,
            isAcceptedAgreement: undefined,
            isExecutedSponsorAgreement: true
        },

        loanDocuments: {
            id: undefined,
            bankStatement: undefined,
            employeeID: undefined,
            employmentLetter: undefined,
            studentId: undefined
        },


    }
   
    const validateSponsorInfo = () => {
        // if ( verifiedData || (loanType !== 'SelfSponsored' && !verifiedData)){
        if ( loanType !== 'SelfSponsored' ){
            return (
                Yup.object({
                firstName: Yup.string().required('First name is empty'),
                lastName: Yup.string().required('Last name is empty'),
                phoneNumber: Yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'Enter a valid phone number').required('Phone Number is empty'),
                email: Yup.string().email('Invalid email format!').required('Email is empty'),
                bvn: Yup.string().matches(/^[0-9]*$/, 'Only numbers allowed').min(11, 'Has to be eleven digits').max(11, 'Has to be eleven digits').required('BVN is empty'),
                relationship: Yup.string().required('Relationship is required'),
            }))
        }
    }
    
    const validationSchema = Yup.object({
        firstName: Yup.string().required('First name is empty'),
        lastName: Yup.string().required('Last name is empty'),
        address: Yup.string().required('Address is empty'),
        dateOfBirth: Yup.date().required('Birth Date is empty'),
        email: Yup.string().email('Invalid email format!').required('Email is empty'),
        gender: Yup.string().required('Gender is empty'),
        phoneNumber: Yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'Enter a valid phone number').required('Phone Number is empty'),
        identificationType: Yup.string().required('Identification Type is required'),
        idNumber: Yup.string().required('ID number is empty'),


        
        sponsorInfo: validateSponsorInfo(),



        guarantors: Yup.array().of(
            Yup.lazy( value => {
                
                if (value.firstName || value.lastName || value.phoneNumber !== undefined) {
                    return Yup.object().shape({
                            firstName: Yup.string().required('First name is empty'),
                            lastName: Yup.string().required('Last name is empty'),
                            phoneNumber: Yup.string().matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/, 'Enter a valid phone number').required('Phone Number is empty'),
                            relationship: Yup.string().required('Relationship is empty'),
                        })
                        
                }
                return Yup.mixed().notRequired()
            }) 
        ),
        bankAccount: Yup.object({
            accountName: Yup.string().required('Account Name is empty'),
            bankCode: Yup.string().required('Choose a bank'),
            accountNumber: Yup.string().matches(/^[0-9]*$/, 'Only numbers allowed').min(10, 'Has to be ten digits').max(10, 'Has to be ten digits').required('Account Number is empty'),
        }),
        employment: Yup.object({
            employmentStatus: Yup.string().required('Select Employment status'),
            employerName: Yup.string().required('Employer name is empty'),
            employerAddress: Yup.string().required('Employer address is empty'),
            workplaceEmail: Yup.string().email('Invalid email format!').required('Account Number is empty'),
            salaryAmount: Yup.number().required('Tell us how much you earn'),
            salaryDueDate: Yup.date().required('Salary due date is empty'),
        }),
        loanRequest: Yup.object({
            loanAmount: Yup.string().required("Request loan amount is empty"),
            tenure: Yup.number().required('Select a loan tenure'),
            isAcceptedAgreement: Yup.bool().oneOf([true], 'Agreement is not accepted').required('Agreement is not accepted'),
        }),

        loanDocuments: Yup.object({
            id: Yup.mixed(),
            bankStatement: Yup.mixed().required('Upload your bank statement'),
            employeeID: Yup.mixed().required('Upload your employee id'),
            employmentLetter: Yup.mixed(),
            studentId: otherInstitution && Yup.mixed().required("Upload your student Id")
        }),
 
        
    })

    const [ , setActiveStep ] = useContext(ActiveStepContext)

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)        
    }

    // set loanRef for upload. In case upload fails
    const [alreadyApplied, setAlreadyApplied] = useState(false)

    // console.log('already', alreadyApplied, isSuccessful);

    const onSubmit = async (values) => {
        const { loanDocuments, ...rest } = values

        let newGuarantors;
        if (rest.guarantors[1].firstName === undefined) {
            newGuarantors = [rest.guarantors[0]]
        } else {
            newGuarantors = rest.guarantors
        }

        let guarantors = newGuarantors

        let loanDocData = new FormData()
        loanDocData.append('id', loanDocuments.id)
        loanDocData.append('bankStatement', loanDocuments.bankStatement)
        loanDocData.append('employeeID', loanDocuments.employeeID)
        loanDocData.append('employmentLetter', loanDocuments.employmentLetter)
        loanDocData.append('studentId', loanDocuments.studentId)
     


        try {
            if (!alreadyApplied) {

                const res = await api.post(`/api/LoanTransaction/apply`, {
                    ...rest,
                    guarantors,
                })

                const message = res.data.message
                
                
                if( res.data.requestSuccessful === true ) {
                    
                    setAlreadyApplied(res.data.responseData.loanRef)
    
                    const uploadDocs = await api.post(`/api/Upload?loanRef=${res.data.responseData.loanRef}`, loanDocData)
        
                    if ( uploadDocs.data.requestSuccessful === true ){
    
                        handleNext()

                    } else {
                        setIsSuccessful({
                            status: false,
                            message: 'Upload failed. Please try again'
                        })
                    }
     
                } else {
                    setIsSuccessful({
                        status: false,
                        message: message
                    })
                } 
            
            } else {

                const uploadDocs = await api.post(`/api/Upload?loanRef=${alreadyApplied}`, loanDocData)
    
                if ( uploadDocs.data.requestSuccessful === true ){
    
                    handleNext()
    
                } else {
    
                    setIsSuccessful({
                        status: false,
                        message: 'Upload failed. Please try again'
                    })
                    
                }
            }

        } catch (error) {
            //error state Login Unsuccessful 
            setIsSuccessful({
                status: false,
                message: 'an error occured'
            })
        }

    }


    
    const [open, setOpen] = React.useState(false);
    // const [scroll, setScroll] = React.useState('paper');

    const handleClickOpen = () => {
        setOpen(true);
        // setScroll(scrollType);
    };

    const handleClose = (accept) => {
        setOpen(false);
    };


    return (
        <Container style={{ maxWidth: '100%' }}>
            <Paper padding="2rem 1rem 2rem 2rem">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                >
                {({values, errors, touched, getFieldProps, setFieldValue, handleBlur, isSubmitting, dirty, isValid}) => (
                    <Form noValidate autoComplete="off">
                        {/* { console.log(errors) }                      */}
                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                Student's Personal Information
                            </Typography>

                            <div className={classes.fieldWrapper}>
                                <TextField 
                                    name="firstName" 
                                    id="firstName" 
                                    label="First Name"
                                    onChange= {e => {
                                        setFieldValue("firstName", e.target.value);
                                        otherInstitution && loanType === "SelfSponsored" && setFieldValue("sponsorInfo.firstName", e.target.value)
                                    }}
                                    // { ...getFieldProps('firstName')}
                                    onBlur={handleBlur}
                                    value={values.firstName}
                                    variant="outlined"
                                    size="small"
                                    disabled={verifiedData ? true : false}
                                    error={errors.firstName && touched.firstName ? true : false}
                                    helperText={ errors.firstName && touched.firstName ?
                                        errors.firstName : null
                                    }
                                />

                                <TextField 
                                    name="lastName" 
                                    id="lastName" 
                                    label="Last Name"
                                    // { ...getFieldProps('lastName')}
                                    onChange= {e => {
                                        setFieldValue("lastName", e.target.value);
                                        otherInstitution && loanType === "SelfSponsored" && setFieldValue("sponsorInfo.lastName", e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.lastName}
                                    variant="outlined"
                                    size="small"
                                    disabled={verifiedData ? true : false}
                                    error={errors.lastName && touched.lastName ? true : false}
                                    helperText={ errors.lastName && touched.lastName ?
                                        errors.lastName : null
                                    }
                                />

                                <TextField 
                                    name="address" 
                                    id="address" 
                                    label="Residential Address"
                                    { ...getFieldProps('address')}
                                    variant="outlined"
                                    size="small"
                                    error={errors.address && touched.address ? true : false}
                                    helperText={ errors.address && touched.address ?
                                        errors.address : null
                                    }
                                />

                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        // disableToolbar
                                        variant="inline"
                                        inputVariant="outlined"
                                        format="Do/MMMM/YYYY"
                                        // margin="normal"
                                        id="dateOfBirth"
                                        name="ateOfBirth"
                                        label="Date of Birth"
                                        size="small"
                                        disabled={verifiedData ? true : false}
                                        value={values.dateOfBirth}
                                        onChange={value => {
                                            setFieldValue("dateOfBirth", value)}
                                        }
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                        error={errors.dateOfBirth && touched.dateOfBirth ? true : false}
                                        helperText={ errors.dateOfBirths && touched.dateOfBirth ?
                                        errors.dateOfBirth : null
                                    }
                                    />
                                </MuiPickersUtilsProvider>

                                <FormControl component="fieldset" disabled={verifiedData ? true : false}>
                                <FormLabel component="legend">Gender</FormLabel>
                                <RadioGroup aria-label="gender" name="gender" { ...getFieldProps('gender')}  className={classes.grouping}>
                                    <FormControlLabel value="M" control={<Radio size="small"/>} label="Male" />
                                    <FormControlLabel value="F" control={<Radio size="small"/>} label="Female" />
                                    <FormControlLabel value="Other" control={<Radio size="small"/>} label="Other" />
                                </RadioGroup>
                                </FormControl>

                                <TextField 
                                    name="email" 
                                    id="email" 
                                    label="Email Address"
                                    // { ...getFieldProps('email')}
                                    onChange= {e => {
                                        setFieldValue("email", e.target.value);
                                        otherInstitution && loanType === "SelfSponsored" && setFieldValue("sponsorInfo.email", e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.email}
                                    variant="outlined"
                                    size="small"
                                    disabled={verifiedData ? true : false}
                                    error={errors.email && touched.email ? true : false}
                                    helperText={ errors.email && touched.email ?
                                        errors.email : null
                                    }
                                />

                                <TextField 
                                    name="phoneNumber" 
                                    id="phoneNumber" 
                                    type="tel"
                                    label="Phone Number"
                                    // { ...getFieldProps('phoneNumber')}
                                    onChange= {e => {
                                        setFieldValue("phoneNumber", e.target.value);
                                        otherInstitution && loanType === "SelfSponsored" && setFieldValue("sponsorInfo.phoneNumber", e.target.value)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.phoneNumber}
                                    variant="outlined"
                                    size="small"
                                    disabled={verifiedData ? true : false}
                                    // className={classes.numberInput}
                                    error={errors.phoneNumber && touched.phoneNumber ? true : false}
                                    helperText={ errors.phoneNumber && touched.phoneNumber ?
                                        errors.phoneNumber : null
                                    }
                                />

                                <FormControl variant="outlined" size="small" className={classes.formControl} error={errors.identificationType && touched.identificationType}>
                                <InputLabel id="id_label">ID Type</InputLabel>
                                    <Select
                                        labelId="id_label"
                                        id="identificationType"
                                        { ...getFieldProps('identificationType')}
                                        label="ID Type"
                                    >
                                    <MenuItem value={'International Passport'}>International Passport</MenuItem>
                                    <MenuItem value={"Voter's Card"}>Voter's Card</MenuItem>
                                    <MenuItem value={"NIM"}>National ID Card</MenuItem>
                                    </Select>
                                    <FormHelperText error={errors.identificationType && touched.identificationType ? true : false}>
                                        { errors.identificationType && touched.identificationType ?
                                            errors.identificationType : null
                                        }
                                    </FormHelperText>
                                </FormControl>

                                <TextField 
                                    name="idNumber" 
                                    id="idNumber" 
                                    label="ID Number"
                                    { ...getFieldProps('idNumber')}
                                    variant="outlined"
                                    size="small"
                                    error={errors.idNumber && touched.idNumber ? true : false}
                                    helperText={ errors.idNumber && touched.idNumber ?
                                        errors.idNumber : null
                                    }
                                />


                            </div>
                        </Box>


                        {/* { loanType !== 'SelfSponsored' && ( */}
                            <Box marginBottom="1.5rem" display={ loanType !== 'SelfSponsored' ? 'block' : 'none' } >
                                <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                    Sponsor's Personal Information 
                                </Typography>

                                <div className={classes.fieldWrapper}>
                                    <TextField 
                                        name= {'sponsorInfo.firstName'}
                                        id="sponsorInfo.firstName" 
                                        label="First Name"
                                        { ...getFieldProps('sponsorInfo.firstName') }
                                        variant="outlined"
                                        size="small"
                                        error={getIn(errors, 'sponsorInfo.firstName')  && getIn(touched, 'sponsorInfo.firstName') ? true : false}
                                        helperText={ getIn(errors, 'sponsorInfo.firstName')  && getIn(touched, 'sponsorInfo.firstName')  ?
                                            getIn(errors, 'sponsorInfo.firstName') : null
                                        }
                                    />

                                    <TextField 
                                        name="sponsorInfo.lastName" 
                                        id="sponsorInfo.lastName" 
                                        label="Last Name"
                                        { ...getFieldProps('sponsorInfo.lastName')}
                                        variant="outlined"
                                        size="small"
                                        error={getIn(errors, 'sponsorInfo.lastName')  && getIn(touched, 'sponsorInfo.lastName') ? true : false}
                                        helperText={ getIn(errors, 'sponsorInfo.lastName')  && getIn(touched, 'sponsorInfo.lastName')  ?
                                            getIn(errors, 'sponsorInfo.lastName') : null
                                        }
                                    />

                                    <TextField 
                                        name="sponsorInfo.email" 
                                        id="sponsorInfo.email" 
                                        label="Email Address"
                                        { ...getFieldProps('sponsorInfo.email')}
                                        variant="outlined"
                                        size="small"
                                        error={getIn(errors, 'sponsorInfo.email')  && getIn(touched, 'sponsorInfo.email') ? true : false}
                                        helperText={ getIn(errors, 'sponsorInfo.email')  && getIn(touched, 'sponsorInfo.email')  ?
                                            getIn(errors, 'sponsorInfo.email') : null
                                        }
                                    />

                                    <TextField 
                                        name="sponsorInfo.phoneNumber" 
                                        id="sponsorInfo.phoneNumber" 
                                        type="tel"
                                        label="Phone Number"
                                        { ...getFieldProps('sponsorInfo.phoneNumber')}
                                        variant="outlined"
                                        size="small"
                                        className={classes.numberInput}
                                        error={getIn(errors, 'sponsorInfo.phoneNumber')  && getIn(touched, 'sponsorInfo.phoneNumber') ? true : false}
                                        helperText={ getIn(errors, 'sponsorInfo.phoneNumber')  && getIn(touched, 'sponsorInfo.phoneNumber')  ?
                                            getIn(errors, 'sponsorInfo.phoneNumber') : null
                                        }
                                    />

                                    <TextField 
                                        name="sponsorInfo.relationship" 
                                        id="sponsorInfo.relationship" 
                                        label="Relationship"
                                        { ...getFieldProps('sponsorInfo.relationship')}
                                        variant="outlined"
                                        size="small"
                                        error={getIn(errors, 'sponsorInfo.relationship')  && getIn(touched, 'sponsorInfo.relationship') ? true : false}
                                        helperText={ getIn(errors, 'sponsorInfo.relationship')  && getIn(touched, 'sponsorInfo.relationship')  ?
                                            getIn(errors, 'sponsorInfo.relationship') : null
                                        }
                                    />

                                </div>
                            </Box>
                         {/* )} */}


                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                Bank Verification Number 
                            </Typography>

                            <div className={classes.fieldWrapper}>

                            <TextField 
                                    name="sponsorInfo.bvn" 
                                    id="sponsorInfo.bvn" 
                                    label="BVN"
                                    { ...getFieldProps('sponsorInfo.bvn')}
                                    variant="outlined"
                                    size="small"
                                    // type="text"
                                    inputProps={{
                                        type: 'text',
                                        pattern: "[0-9]*",
                                        inputMode:"numeric"
                                    }}
                                    className={classes.numberInput}
                                    error={getIn(errors, 'sponsorInfo.bvn')  && getIn(touched, 'sponsorInfo.bvn') ? true : false}
                                    helperText={ getIn(errors, 'sponsorInfo.bvn')  && getIn(touched, 'sponsorInfo.bvn')  ?
                                        getIn(errors, 'sponsorInfo.bvn') : null
                                    }
                                />

                            </div>
                        </Box>



                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                guarantors Information
                            </Typography>
                            
                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '.5rem' }}>
                                guarantor 1
                            </Typography>
                            <div className={classes.fieldWrapper}>
                            
                            <TextField 
                                name="guarantors[0].firstName" 
                                id="guarantors[0].firstName" 
                                label="First Name"
                                { ...getFieldProps('guarantors[0].firstName')}
                                variant="outlined"
                                size="small"
                                error={getIn(errors, 'guarantors[0].firstName')  && getIn(touched, 'guarantors[0].firstName') ? true : false}
                                helperText={ getIn(errors, 'guarantors[0].firstName')  && getIn(touched, 'guarantors[0].firstName')  ?
                                    getIn(errors, 'guarantors[0].firstName') : null
                                }
                            />

                            <TextField 
                                name="guarantors[0].lastName" 
                                id="guarantors[0].lastName" 
                                label="Last Name"
                                { ...getFieldProps('guarantors[0].lastName')}
                                variant="outlined"
                                size="small"
                                error={getIn(errors, 'guarantors[0].lastName')  && getIn(touched, 'guarantors[0].lastName') ? true : false}
                                helperText={ getIn(errors, 'guarantors[0].lastName')  && getIn(touched, 'guarantors[0].lastName')  ?
                                    getIn(errors, 'guarantors[0].lastName') : null
                                }
                            />


                            <TextField 
                                    name="guarantors[0].phoneNumber" 
                                    id="guarantors[0].phoneNumber" 
                                    type="tel"
                                    label="Phone Number"
                                    { ...getFieldProps('guarantors[0].phoneNumber')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.numberInput}
                                    error={getIn(errors, 'guarantors[0].phoneNumber')  && getIn(touched, 'guarantors[0].phoneNumber') ? true : false}
                                    helperText={ getIn(errors, 'guarantors[0].phoneNumber')  && getIn(touched, 'guarantors[0].phoneNumber')  ?
                                        getIn(errors, 'guarantors[0].phoneNumber') : null
                                    }
                                />

                                

                            <TextField 
                                name="guarantors[0].relationship" 
                                id="guarantors[0].relationship" 
                                label="Relationship"
                                { ...getFieldProps('guarantors[0].relationship')}
                                variant="outlined"
                                size="small"
                                error={getIn(errors, 'guarantors[0].relationship')  && getIn(touched, 'guarantors[0].relationship') ? true : false}
                                helperText={ getIn(errors, 'guarantors[0].relationship')  && getIn(touched, 'guarantors[0].relationship')  ?
                                    getIn(errors, 'guarantors[0].relationship') : null
                                }
                            />



                            </div>

                            
                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '.5rem' }}>
                                guarantor 2
                            </Typography>
                            <div className={classes.fieldWrapper}>
                            
                            <TextField 
                                name="guarantors[1].firstName" 
                                id="guarantors[1].firstName" 
                                label="First Name"
                                { ...getFieldProps('guarantors[1].firstName')}
                                variant="outlined"
                                size="small"
                                error={getIn(errors, 'guarantors[1].firstName')  && getIn(touched, 'guarantors[1].firstName') ? true : false}
                                helperText={ getIn(errors, 'guarantors[1].firstName')  && getIn(touched, 'guarantors[1].firstName')  ?
                                    getIn(errors, 'guarantors[1].firstName') : null
                                }
                            />

                            <TextField 
                                name="guarantors[1].lastName" 
                                id="guarantors[1].lastName" 
                                label="Last Name"
                                { ...getFieldProps('guarantors[1].lastName')}
                                variant="outlined"
                                size="small"
                                error={getIn(errors, 'guarantors[1].lastName')  && getIn(touched, 'guarantors[1].lastName') ? true : false}
                                helperText={ getIn(errors, 'guarantors[1].lastName')  && getIn(touched, 'guarantors[1].lastName')  ?
                                    getIn(errors, 'guarantors[1].lastName') : null
                                }
                            />


                            <TextField 
                                    name="guarantors[1].phoneNumber" 
                                    id="guarantors[1].phoneNumber" 
                                    type="tel"
                                    label="Phone Number"
                                    { ...getFieldProps('guarantors[1].phoneNumber')}
                                    variant="outlined"
                                    size="small"
                                    className={classes.numberInput}
                                    error={getIn(errors, 'guarantors[1].phoneNumber')  && getIn(touched, 'guarantors[1].phoneNumber') ? true : false}
                                    helperText={ getIn(errors, 'guarantors[1].phoneNumber')  && getIn(touched, 'guarantors[1].phoneNumber')  ?
                                        getIn(errors, 'guarantors[1].phoneNumber') : null
                                    }
                                />

                                

                            <TextField 
                                name="guarantors[1].relationship" 
                                id="guarantors[1].relationship" 
                                label="Relationship"
                                { ...getFieldProps('guarantors[1].relationship')}
                                variant="outlined"
                                size="small"
                                error={getIn(errors, 'guarantors[1].relationship')  && getIn(touched, 'guarantors[1].relationship') ? true : false}
                                helperText={ getIn(errors, 'guarantors[1].relationship')  && getIn(touched, 'guarantors[1].relationship')  ?
                                    getIn(errors, 'guarantors[1].relationship') : null
                                }
                            />



                            </div>
                        </Box>

                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                Bank Account Details
                            </Typography>

                            <div className={classes.fieldWrapper}>

                            <TextField 
                                    name="bankAccount.accountName" 
                                    id="bankAccount.accountName" 
                                    label="Account Name"
                                    { ...getFieldProps('bankAccount.accountName')}
                                    variant="outlined"
                                    size="small"
                                    error={getIn(errors, 'bankAccount.accountName')  && getIn(touched, 'bankAccount.accountName') ? true : false}
                                    helperText={ getIn(errors, 'bankAccount.accountName')  && getIn(touched, 'bankAccount.accountName')  ?
                                        getIn(errors, 'bankAccount.accountName') : null
                                    }
                            />

                            <TextField 
                                    name="bankAccount.accountNumber" 
                                    id="bankAccount.accountNumber" 
                                    label="Account Number"
                                    { ...getFieldProps('bankAccount.accountNumber')}
                                    variant="outlined"
                                    // type="number"
                                    inputProps={{
                                        type: 'text',
                                        pattern: "[0-9]*",
                                        inputMode:"numeric"
                                    }}
                                    size="small"
                                    className={classes.numberInput}
                                    error={getIn(errors, 'bankAccount.accountNumber')  && getIn(touched, 'bankAccount.accountNumber') ? true : false}
                                    helperText={ getIn(errors, 'bankAccount.accountNumber')  && getIn(touched, 'bankAccount.accountNumber')  ?
                                        getIn(errors, 'bankAccount.accountNumber') : null
                                    }
                            />

                            <FormControl variant="outlined" size="small" className={classes.formControl} error={getIn(errors, 'bankAccount.bankCode')  && getIn(touched, 'bankAccount.bankCode')}>
                                <InputLabel id="bankCode_label">Select Bank</InputLabel>
                                    <Select
                                        labelId="bankCode_label"
                                        id="bankAccount.bankCode"
                                        { ...getFieldProps('bankAccount.bankCode')}
                                        label="Bank"
                                    >
                                        {banks.map((bank, key) => (
                                            <MenuItem key={key} value={bank.bankCode}>{ bank.bankName }</MenuItem>

                                        ))}
                                    </Select>
                                    <FormHelperText error={getIn(errors, 'bankAccount.bankCode')  && getIn(touched, 'bankAccount.bankCode')? true : false}>
                                        { getIn(errors, 'bankAccount.bankCode')  && getIn(touched, 'bankAccount.bankCode') ?
                                            getIn(errors, 'bankAccount.bankCode') : null
                                        }
                                    </FormHelperText>
                                    
                                </FormControl>

                            </div>
                        </Box>


                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                Employment Details
                            </Typography>

                            <div className={classes.fieldWrapper}>

                                <FormControl component="fieldset" error={getIn(errors, 'employment.employmentStatus')  && getIn(touched, 'employment.employmentStatus')}>
                                <FormLabel component="legend">Employment Status</FormLabel>
                                <RadioGroup aria-label="employmentStatus" name="employment.employmentStatus" { ...getFieldProps('employment.employmentStatus')}  className={classes.grouping}>
                                    <FormControlLabel value="Employed" control={<Radio size="small"/>} label="Employed" />
                                    {/* <FormControlLabel value="Unemployed" control={<Radio size="small"/>} label="Unemployed" /> */}
                                    <FormControlLabel value="Self-employed" control={<Radio size="small"/>} label="Self-employed" />
                                </RadioGroup>
                                <FormHelperText error={getIn(errors, 'employment.employmentStatus')  && getIn(touched, 'employment.employmentStatus') ? true : false}>
                                    { getIn(errors, 'employment.employmentStatus')  && getIn(touched, 'employment.employmentStatus') ?
                                        getIn(errors, 'employment.employmentStatus') : null
                                    }
                                </FormHelperText>
                                </FormControl>

                                <TextField 
                                    name="employment.employerName" 
                                    id="employment.employerName" 
                                    label="Employer Name"
                                    { ...getFieldProps('employment.employerName')}
                                    variant="outlined"
                                    size="small"
                                    error={getIn(errors, 'employment.employerName')  && getIn(touched, 'employment.employerName') ? true : false}
                                    helperText={ getIn(errors, 'employment.employerName')  && getIn(touched, 'employment.employerName')  ?
                                        getIn(errors, 'employment.employerName') : null
                                    }
                                />

                                <TextField 
                                    name="employment.employerAddress" 
                                    id="employment.employerAddress" 
                                    label="Employer Address"
                                    { ...getFieldProps('employment.employerAddress')}
                                    variant="outlined"
                                    size="small"
                                    error={getIn(errors, 'employment.employerAddress')  && getIn(touched, 'employment.employerAddress') ? true : false}
                                    helperText={ getIn(errors, 'employment.employerAddress')  && getIn(touched, 'employment.employerAddress')  ?
                                        getIn(errors, 'employment.employerAddress') : null
                                    }
                                />

                                <TextField 
                                    name="employment.workplaceEmail" 
                                    id="employment.workplaceEmail" 
                                    label="Work Email"
                                    { ...getFieldProps('employment.workplaceEmail')}
                                    variant="outlined"
                                    size="small"
                                    error={getIn(errors, 'employment.workplaceEmail')  && getIn(touched, 'employment.workplaceEmail') ? true : false}
                                    helperText={ getIn(errors, 'employment.workplaceEmail')  && getIn(touched, 'employment.workplaceEmail')  ?
                                        getIn(errors, 'employment.workplaceEmail') : null
                                    }
                                />
                                
                                <TextField 
                                    name="employment.salaryAmount" 
                                    id="employment.salaryAmount" 
                                    label="Salary Amount"
                                    { ...getFieldProps('employment.salaryAmount')}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    className={classes.numberInput}
                                    error={getIn(errors, 'employment.salaryAmount')  && getIn(touched, 'employment.salaryAmount') ? true : false}
                                    helperText={ getIn(errors, 'employment.salaryAmount')  && getIn(touched, 'employment.salaryAmount')  ?
                                        getIn(errors, 'employment.salaryAmount') : null
                                    }
                                />

                                <TextField 
                                    name="employment.salaryDueDate" 
                                    id="employment.salaryDueDate" 
                                    label="Salary due date"
                                    { ...getFieldProps('employment.salaryDueDate')}
                                    variant="outlined"
                                    size="small"
                                    type="date"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    error={getIn(errors, 'employment.salaryDueDate')  && getIn(touched, 'employment.salaryDueDate') ? true : false}
                                    helperText={ getIn(errors, 'employment.salaryDueDate')  && getIn(touched, 'employment.salaryDueDate')  ?
                                        getIn(errors, 'employment.salaryDueDate') : null
                                    }
                                />

                            </div>
                        </Box>


                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                Loan Details
                            </Typography>
                            <div className={classes.fieldWrapper}>
                            {/* <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '.5rem' }}>
                                How much would you like to request for?
                            </Typography> */}

                            {/* <div className={classes.fieldWrapper}> */}

                                <TextField 
                                    name="loanRequest.loanAmount" 
                                    id="loanRequest.loanAmount" 
                                    label="Loan Amount"
                                    { ...getFieldProps('loanRequest.loanAmount')}
                                    variant="outlined"
                                    size="small"
                                    type="number"
                                    disabled={amount ? true : false}
                                    className={classes.numberInput}
                                    error={getIn(errors, 'loanRequest.loanAmount')  && getIn(touched, 'loanRequest.loanAmount') ? true : false}
                                    helperText={ getIn(errors, 'loanRequest.loanAmount')  && getIn(touched, 'loanRequest.loanAmount')  ?
                                        getIn(errors, 'loanRequest.loanAmount') : null
                                    }
                                />

                            

                            {/* <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '.5rem' }}>
                                How much would you like to request for?
                            </Typography> */}

                                <FormControl variant="outlined" size="small" className={classes.formControl} error={getIn(errors, 'loanRequest.tenure')  && getIn(touched, 'loanRequest.tenure')}>   
                                <InputLabel id="loan_tenure">Loan Tenure</InputLabel>
                                    <Select
                                        labelId="loan_tenure"
                                        id="tenure"
                                        { ...getFieldProps('loanRequest.tenure')}
                                        label="Loan Tenure"
                                    >
                                    <MenuItem value={4}>4 Months</MenuItem>
                                    <MenuItem value={6}>6 Months</MenuItem>
                                    </Select>
                                    <FormHelperText error={getIn(errors, 'loanRequest.tenure')  && getIn(touched, 'loanRequest.tenure')? true : false}>
                                        { getIn(errors, 'loanRequest.tenure')  && getIn(touched, 'loanRequest.tenure') ?
                                            getIn(errors, 'loanRequest.tenure') : null
                                        }
                                    </FormHelperText>
                                </FormControl>

                            </div>

                        </Box>



                        <Box marginBottom="1.5rem">
                            <Typography variant="body2" style={{ marginBottom: '1rem' }}>
                                Document Uploads
                            </Typography>

                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '1rem' }}>
                                Identification (NIM, Voter's Card or International Passport as stated in <strong>Student's Personal Information</strong> above) - Optional
                            </Typography>

                            <div className={classes.fieldWrapper}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                <DropzoneArea
                                    acceptedFiles={['image/*', 'application/pdf', 'application/msword']}
                                    dropzoneText={"Upload your ID here"}
                                    maxFileSize={3000000}
                                    getDropRejectMessage = { () => "File should not be more than 3MB"}
                                    filesLimit={1}
                                    onChange={(file) =>setFieldValue("loanDocuments.id", file[0])}
                                />
                                <FormHelperText style={{ textAlign: 'center' }} error={true}>
                                    { getIn(errors, 'loanDocuments.id')  && getIn(touched, 'loanDocuments.id')  ?
                                        getIn(errors, 'loanDocuments.id') : null
                                    }
                                </FormHelperText>
                                </FormControl>
                            </div>

                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '1rem' }}>
                                Bank Statement (dating back 6 months) - PDF only
                            </Typography>

                            <div className={classes.fieldWrapper}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                <DropzoneArea
                                    acceptedFiles={['application/pdf']}
                                    dropzoneText={"Upload your Bank Statement here"}
                                    maxFileSize={3000000}
                                    getDropRejectMessage = { () => "File should not be more than 3MB"}
                                    filesLimit={1}
                                    onChange={(file) => setFieldValue("loanDocuments.bankStatement", file[0])}
                                />
                                <FormHelperText style={{ textAlign: 'center' }} error={true}>
                                    { getIn(errors, 'loanDocuments.bankStatement')  && getIn(touched, 'loanDocuments.bankStatement')  ?
                                        getIn(errors, 'loanDocuments.bankStatement') : null
                                    }
                                </FormHelperText>
                                </FormControl>
                            </div>

                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '1rem' }}>
                                Employee ID
                            </Typography>

                            <div className={classes.fieldWrapper}>
                                <FormControl component="fieldset" style={{ width: '100%' }}>
                                <DropzoneArea
                                    acceptedFiles={['image/*', 'application/pdf', 'application/msword']}
                                    dropzoneText={"Upload your Employee ID here"}
                                    maxFileSize={3000000}
                                    getDropRejectMessage = { () => "File should not be more than 3MB"}
                                    filesLimit={1}
                                    onChange={(file) => setFieldValue("loanDocuments.employeeID", file[0])}
                                />
                                <FormHelperText style={{ textAlign: 'center' }} error={true}>
                                    { getIn(errors, 'loanDocuments.employeeID')  && getIn(touched, 'loanDocuments.employeeID')  ?
                                        getIn(errors, 'loanDocuments.employeeID') : null
                                    }
                                </FormHelperText>
                                </FormControl>
                            </div>

                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '1rem' }}>
                                Employment Letter - optional
                            </Typography>

                            <div className={classes.fieldWrapper}>
                                <FormControl component="fieldset" style={{ width: '100%' }} error={getIn(errors, 'loanDocuments.employmentLetter')  && getIn(touched, 'loanDocuments.employmentLetter') ? true : false}>
                                <DropzoneArea
                                    acceptedFiles={['image/*', 'application/pdf', 'application/msword']}
                                    dropzoneText={"Upload your Employment Letter here"}
                                    maxFileSize={3000000}
                                    getDropRejectMessage = { () => "File should not be more than 3MB"}
                                    filesLimit={1}
                                    onChange={(file) => setFieldValue("loanDocuments.employmentLetter", file[0])}
                                />
                                <FormHelperText  style={{ textAlign: 'center' }} error={getIn(errors, 'loanDocuments.employmentLetter')  && getIn(touched, 'loanDocuments.employmentLetter') ? true : false}>
                                        { getIn(errors, 'loanDocuments.employmentLetter')  && getIn(touched, 'loanDocuments.employmentLetter') ?
                                            getIn(errors, 'loanDocuments.employmentLetter') : null
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            
                            <Box style={{display: otherInstitution ? 'block' : 'none'}}>
                            <Typography variant="body1" style={{ fontWeight: '300', marginBottom: '1rem' }}>
                                Student Id card
                            </Typography>

                            <div className={classes.fieldWrapper}>
                                <FormControl component="fieldset" style={{ width: '100%' }} error={getIn(errors, 'loanDocuments.studentId')  && getIn(touched, 'studentId') ? true : false}>
                                <DropzoneArea
                                    acceptedFiles={['image/*', 'application/pdf', 'application/msword']}
                                    dropzoneText={"Upload your student Id here"}
                                    maxFileSize={3000000}
                                    getDropRejectMessage = { () => "File should not be more than 3MB"}
                                    filesLimit={1}
                                    onChange={(file) => setFieldValue("loanDocuments.studentId", file[0])}
                                />
                                <FormHelperText  style={{ textAlign: 'center' }} error={getIn(errors, 'loanDocuments.studentId')  && getIn(touched, 'loanDocuments.studentId') ? true : false}>
                                        { getIn(errors, 'loanDocuments.studentId')  && getIn(touched, 'loanDocuments.studentId') ?
                                            getIn(errors, 'loanDocuments.studentId') : null
                                        }
                                    </FormHelperText>
                                </FormControl>
                            </div>
                            </Box>
                        </Box>


                        <Box marginBottom="1.5rem">
                        <FormControl className={classes.formControl}>
                        <FormControlLabel
                            control={
                            <Checkbox 
                                { ...getFieldProps('loanRequest.isAcceptedAgreement')}
                                name="loanRequest.isAcceptedAgreement"
                                color="primary"
                            />
                            }
                            label={<Typography onClick={handleClickOpen} variant="body2" style={{ color: '#117AF3', textDecoration: 'underline' }}>Terms and Conditions</Typography>}
                        />
                        
                        <FormHelperText error={getIn(errors, 'loanRequest.isAcceptedAgreement')  && getIn(touched, 'loanRequest.isAcceptedAgreement') ? true : false}>
                                    { getIn(errors, 'loanRequest.isAcceptedAgreement')  && getIn(touched, 'loanRequest.isAcceptedAgreement') ?
                                        getIn(errors, 'loanRequest.isAcceptedAgreement') : null
                                    }
                        </FormHelperText>
                        </FormControl>
                        </Box>


                        <Box marginBottom="1.5rem">

                            <FormControl className={classes.formControl}>
                                <Button 
                                    variant="contained" 
                                    color="secondary" 
                                    type="submit"
                                    disabled={isSubmitting}
                                    loading={isSubmitting}
                                >
                                        Submit Request
                                </Button>  

                                <FormHelperText style={{ textAlign: 'center' }} error={true}>
                                    {!isValid && dirty ? 'Some fields are empty' : null}
                                    {/* {errors !== {} && touched.loanRequest?.isAcceptedAgreement !== {} ? 'Some fields are empty' : null} */}
                                    {isSuccessful?.status === false ? isSuccessful.message ? isSuccessful.message : 'an error occured' : null}
                                </FormHelperText>

                            </FormControl>
                        </Box>   
                    </Form>
                   
            )}
            </Formik>

            </Paper>


            <Dialog
                open={open}
                onClose={handleClose}
                scroll={'paper'}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Agreement Summary</DialogTitle>
                <DialogContent dividers={true}>
                <DialogContentText
                    id="scroll-dialog-description"
                    // ref={descriptionElementRef}
                    tabIndex={-1}
                >
                        Dear customer, thank you for using our service. Please read carefully CredPal’s customer agreement summarized below for
                        your information and execution.
                        You agree that by appending your signature below, you will fulfil your financial obligation which includes prompt repayment of
                        the credit sum and interest rate to CredPal, if you refuse to repay the credit sum (including the interest rate) allocated to you,
                        CredPal has the right to take any or all of the following action against you;
                        
                        <ul>
                            <li>CredPal may notify the Credit Bureau of your default, this will result in you being unable to get credit for at least 10 years.</li>

                            <li>
                                CredPal may notify your employer of your indebtedness.
                            </li>
                        </ul>
                        

                        CredPal may institute legal proceedings against you and is under no obligation to inform you before the proceedings
                        commence and you shall be responsible for all legal costs and expenses incurred by CredPal in attempting to obtain
                        repayment of any outstanding loan balance owed by you. Interest on any amount which becomes due and payable shall be
                        charged.
        
        
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <MuiButton onClick={handleClose}>
                    Close
                </MuiButton>
                
                </DialogActions>
            </Dialog>

        </Container>
    )
}

LoanRequestForm.propTypes = {

}

export default LoanRequestForm
