import React from 'react'
import CredPalForm from '../components/LoanTypes/CredPalForm'
import SEO from "../components/seo"
import Layout from "../components/Layout/"

const ApplicationPage = ({ location }) => {

    return (
        <div>
            <SEO title="Application" />
            <Layout transparentHeader>
            {/* {location.state?.loanType === 'credPal' && ( */}
                <CredPalForm location={location} />
            {/* )
            } */}
            </Layout>
        </div>
    )
}

export default ApplicationPage
